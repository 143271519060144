<template>
  <div>
    <Breadcrumb :breadcrumbList="breadcrumbList" />
    <!-- 顶部图片 -->
    <div class="header">SUCCESS STORIES</div>
    <!-- 主体页面 -->
    <div class="main">
      <div class="main_title">
        {{ msg.name }}
      </div>
      <div class="main_title2">
        {{ msg.titleDescription }}
      </div>
      <div class="main_content">
        <img :src="msg.detailsImgUrl" alt="" />
        <div>{{ msg.detailsDescription }}</div>
      </div>
      <div class="main_reachBox">
        <div class="main_reachBox_title">
          The objectives that CHdN wanted to reach were many:
        </div>
        <div v-for="(item, index) in msg.contentList" :key="index" v-html="item.content">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Breadcrumb from "@/components/breadcrumb/breadcrumb";
import api from "@/utils/api";
export default {
  created() {
    //获取详情页的id
    this.getMsg(this.$route.query.id);
    //通过id获取页面信息
    //     ……    //
  },
  mounted() {
    //面包屑导航
    this.breadcrumbList = [
      { path: "/", title: "HOME" },
      { path: "", title: "PRODUCTS" },
      { path: "", title: this.msg.title },
    ];
  },
  data() {
    return {
      breadcrumbList: [],
      msg: {}, //页面信息
    };
  },
  methods: {
    //获取页面信息
    getMsg(id) {
      // console.log(id);
      //假装获取到了数据
      api.successStoriesInfo({ id }).then((res) => {
        let data = res.data;
        if (data.code == 200) {
           this.msg = data.data;
          //  console.log(this.msg);
        } else {
          this.$message.error(data.msg);
        }
      });
    },
  },
  components: {
    Breadcrumb,
  },
};
</script>

<style lang="scss" scoped>
.header {
  background-image: url("../../assets/index/carousel.png");
  background-repeat: no-repeat;
  background-size: 100%;
  height: 186px;
  min-width: 1156px;
  font-size: 56px;
  font-family: Arial;
  font-weight: bold;
  color: #ffffff;
  line-height: 186px;
  text-align: center;
}
.main {
  width: 1156px;
  margin: 0 auto;
  .main_title {
    font-size: 32px;
    font-family: Arial;
    font-weight: normal;
    color: #004a9c;
    text-align: center;
    padding: 33px 0 84px 0;
  }
  .main_title2 {
    font-size: 16px;
    font-family: Arial;
    font-weight: normal;
    color: #5a5b5e;
    margin-bottom: 56px;
  }
  .main_content {
    display: flex;
    margin-bottom: 60px;
    img {
      height: 250px;
      width: 250px;
    }
    div {
      margin-left: 24px;
      font-size: 24px;
      font-family: Arial;
      font-weight: normal;
 
      line-height: 30px;
      display: flex;
      align-items: center;
    }
  }
  .main_reachBox {
    .main_reachBox_title {
      margin-bottom: 40px;
    }
    font-size: 16px;
    font-family: Arial;
    font-weight: normal;
    color: #5a5b5e;
    line-height: 24px;
    margin-bottom: 50px;
  }
}
</style>